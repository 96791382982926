<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile"
      min-height="400px">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">ARNIE History{{ isXmlViewerModalActive ? ` - Version ${version}` : '' }}</p>
            </div>
            <div class="column is-flex is-justify-content-right">
              <button v-show="isXmlViewerModalActive"
                class="button is-primary tooltip"
                @click="isXmlViewerModalActive = false"
                data-tooltip="Back to listing">
                <i class="mdi mdi-arrow-left-thick" />
              </button>
            </div>
          </div>
          <p class="subtitle">
            <span>Next expected version: <span v-if="arnieInnerValue"
              class="has-text-success has-text-weight-bold"> {{ nextArnieSend }} </span></span>
          </p>
          <bulma-table v-if="!isXmlViewerModalActive && arnieInnerValue"
            class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="arnieInnerValue.length"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-header="true"
            :show-pagination="false">
            <tr v-for="(arnie) in arnieInnerValue"
              :key="arnie.version">
              <td class="has-text-centered">{{ arnie.version }}</td>
              <td class="has-text-right">{{ $filters.formatDateTimeLocale(arnie.sentDateTime, $user.info.locale) }}</td>
              <td class="has-text-centered is-content-width">
                <a class="button is-primary is-small is-inverted tooltip"
                  @click.prevent="view(arnie)"
                  data-tooltip="Click to view">
                  <span class="icon is-medium">
                    <i class="mdi mdi-xml mdi-24px" />
                  </span>
                </a>
              </td>
              <td class="has-text-centered is-content-width">
                <div class="field">
                  <div class="pretty p-switch p-fill">
                    <input type="checkbox"
                      v-model="arnie.isSuccess"
                      :disabled="isChangeDisable(arnie.version,arnie.isSuccess)"
                      @change="sucessFailOnChange($event,arnie.version)"
                      :checked="arnie.isSuccess ? true : false">
                    <div class="state p-primary">
                      <label> &nbsp;</label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </bulma-table>
          <div class="arnie-codemirror">
            <codemirror v-show="isXmlViewerModalActive"
              v-model="code"
              :options="options" />
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { ArnieColumns } from './columns'
import { codemirror } from '@/components/VueCodemirror'
import _orderBy from 'lodash/orderBy'
import QuoteService from './QuoteService'
import 'codemirror/mode/xml/xml.js'
import { EventHubTypes } from '@/enums'

export default {
  name: 'QuoteArnie',
  components: {
    BulmaTable,
    codemirror
  },
  mixins: [],
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null,
      arnieInnerValue: null,
      nextArineCnt: 1,
      filter: {
        sortColumn: ArnieColumns[0].name,
        sortOrder: ArnieColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 50
      },
      isTableLoading: false,
      isXmlViewerModalActive: false,
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: true,
        mode: 'application/xml',
        theme: 'default'
      },
      code: '',
      version: '',
      isFetching: false
    }
  },
  computed: {
    columns() {
      return ArnieColumns
    },

    maxArnieVersion() {
      return Math.max.apply(
        Math,
        this.arnieInnerValue.map(function (c) {
          return c.version
        })
      )
    },
    lastArnieVersion() {
      const arnieLength = this.arnieInnerValue ? this.arnieInnerValue.length : 0
      let version = this.arnieInnerValue[arnieLength - 1].version
      return version
    },
    lastArnieStatus() {
      const arnieLength = this.arnieInnerValue ? this.arnieInnerValue.length : 0
      let status = this.arnieInnerValue[arnieLength - 1].isSuccess
      return status
    },
    nextArnieSend() {
      let maxVersionSucessCnt = this.getSucessVersionCnt(this.maxArnieVersion)
      if (this.lastArnieVersion == this.maxArnieVersion && this.lastArnieStatus) {
        return this.maxArnieVersion + 1
      } else if (this.lastArnieVersion != this.maxArnieVersion && maxVersionSucessCnt > 0) {
        return this.maxArnieVersion + 1
      } else if (this.maxArnieVersion == 1 && !this.lastArnieStatus) {
        return this.maxArnieVersion
      } else if (this.maxArnieVersion == 1 && this.lastArnieStatus) {
        return this.maxArnieVersion + 1
      } else {
        let version = 1
        if (this.arnieInnerValue) {
          for (var i = this.arnieInnerValue.length - 1; i >= 0; i--) {
            if (this.arnieInnerValue[i].isSuccess) {
              version = this.arnieInnerValue[i].version + 1
              break
            }
          }
        }
        return version
      }
    }
  },
  watch: {},
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntityReloaded)
  },
  created() {
    this.$eventHub.$on(EventHubTypes.EntityReloaded, () => {
      this.getArnies()
    })
    this.getArnies()
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getArnies() {
      this.arnieInnerValue = await QuoteService.getQuoteArnies(this.value.quoteId)
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.arnieInnerValue = _orderBy(this.arnieInnerValue, this.filter.sortColumn, this.filter.sortOrder)
    },
    async view(arnie) {
      let xml = arnie.xml
      if (!arnie.xml) {
        xml = await this.getQuoteArnieXml(arnie.quoteArnieId)
      }
      this.code = xml
      this.version = arnie.version
      this.isXmlViewerModalActive = true
    },
    async getQuoteArnieXml(quoteArnieId) {
      this.$showSpinner()
      let xml = ''
      try {
        xml = await QuoteService.getQuoteArnieXml(this.value.quoteId, quoteArnieId)
      } catch {
      } finally {
        this.$hideSpinner()
      }
      return xml
    },
    getSucessVersionCnt(version) {
      let sucessVersionCnt = 0
      this.arnieInnerValue.map((elem) => {
        if (elem.version === version && elem.isSuccess === true) {
          ++sucessVersionCnt
        }
      })
      return sucessVersionCnt
    },
    isChangeDisable(version, sucess) {
      let previousVersion = version - 1
      let nextVersion = version + 1
      let previousSucessCnt = this.getSucessVersionCnt(previousVersion)
      let nextSucessCnt = this.getSucessVersionCnt(nextVersion)
      if (this.$userInfo.isSupportUser) {
        if (version != 1 && version == this.maxArnieVersion && version == this.lastArnieVersion) {
          if (previousSucessCnt > 0) {
            return false
          } else {
            return true
          }
        } else if (version == 1 && this.maxArnieVersion == 1) {
          return false
        } else if (version == 1 && nextSucessCnt > 0) {
          return true
        } else if (version != 1 && sucess && previousSucessCnt > 0 && nextSucessCnt > 0) {
          return true
        } else if (version != 1 && !sucess && previousSucessCnt == 0) {
          return true
        }
      } else {
        return true
      }
    },
    async sucessFailOnChange(event, version) {
      await QuoteService.updateQuoteArnieSucessFail(this.value.quoteId, version, event.target.checked)
      this.getArnies()
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.arnie-codemirror {
  // Height minus header and menu
  height: calc(100vh - 250px);
  width: 100%;
}
.status-width {
  min-width: 8em;
}
</style>
